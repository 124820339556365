import axios from 'axios'

import { backendURL } from '../../config'
import { LOGIN_USER } from '../../actions/user'
import { SELECT_SURVEY } from '../../actions/surveys'

import { GA_LoggedIn } from '../../analytics.js'
import { GA_FailedLogin } from '../../analytics.js'

export const fetchLogin = (email, password) => {
  const base64_email = btoa(email)
  const base64_password = btoa(password)
  const dataToSend = `_${base64_email}:TOG:${base64_password}_`

  return async (dispatch) => {
    try {
      const response = await axios.get(`${backendURL}/api/v1/auth/login/${dataToSend}`)
      GA_LoggedIn()
      dispatch(SELECT_SURVEY(response.data.surveys[0].id))
      return dispatch(LOGIN_USER(response.data))
    } catch (err) {
      GA_FailedLogin()
      console.log('An Error Occurred...', err)
      const error = err.response ? err.response.data : err.message
      throw new Error(error)
    }
  }
}