import axios from 'axios'
import { backendURL } from '../config.js'

const fetchAllUsers = async () => {
    const response = await axios.get(backendURL + '/api/v1/user/getAll')
    return response.data
}

const updateUserPresets = async (userId, presets) => {
    const dataToSend = { userId, presets }
    const response = await axios.patch(backendURL + '/api/v1/user/updatePresets', dataToSend)
    return response.data
}

const createUser = async (newUser) => {
    try {
        const response = await axios.post(backendURL + '/api/v1/user/create', newUser)
        return response.data
    } catch(err) {
        throw err.response ? err.response.data : err.message
    }
}

const updateUser = async (user) => {
    const response = await axios.patch(backendURL + '/api/v1/user/updateUser', user)
    return response.data
}

const deleteUser = async (user_id) => {
    const response = await axios.delete(backendURL + '/api/v1/user/' + user_id)
    return response.data
}

const handleUpdateLogin = async (email) => {
    try {
        const response = await axios.patch(backendURL + '/api/v1/account/updateLogin', {email})
        return response.data
    } catch(err) {
        throw Error(err.response ? err.response.data : err.message)
    }
}

const handleUpdatePassword = async (oldPassword, newPassword) => {
    try {
    const passwords = btoa(JSON.stringify({ oldPassword, newPassword }), 'utf-8')
    const response = await axios.patch(backendURL + '/api/v1/account/updatePassword', {passwords})
    return response.data
    } catch(err) {
        throw Error(err.response ? err.response.data : err.message)
    }
}

export { fetchAllUsers, updateUserPresets, createUser, updateUser, deleteUser, handleUpdateLogin, handleUpdatePassword }