import axios from 'axios'
import { backendURL } from '../../../config'

export const fetchSurveyDataById = async (survey_id, dates, getNotificationSurveys) => {
  try {
    if (!dates) dates = { startDate: new Date(), endDate: new Date() }
    const dataToSend = btoa(JSON.stringify({ ...dates, survey_id, getNotificationSurveys }))
    const surveyData = await axios.get(`${backendURL}/api/v1/surveys/${dataToSend}`)
    return surveyData.data
  } catch (err) {
    const error = err.response ? err.response.data : err.message
    alert(error)
    throw(error)
  }
}

export const formatPreset = (surveys, stores) => {
  const filteredStores = stores.locations.map(store => surveys[store]).filter(store => store)
  return [].concat.apply([], filteredStores)
}